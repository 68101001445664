import bot from "../icons/bot.png";
import user from "../icons/user.png";

import styles from "./Message.module.css";

export default function Message({ role, content }) {
  return (
    <div className="flex min-h-14 p-4 mb-5 rounded-xl bg-gray-700">
      <div className="flex-none w-14">
        <img
          src={role === "assistant" ? bot : user}
          className={styles.avatar}
          alt="profile avatar"
        />
      </div>
      <div className="flex-1">
        <p className="text-white">{content}</p>
      </div>
    </div>
  );
}

