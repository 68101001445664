import styles from "./Input.module.css";

export default function Input({ value, onChange, onClick }) {
  return (
    <div className={styles.wrapper}>
      <input
        className={styles.text}
        placeholder="Ada yang bisa saya bantu?"
        value={value}
        onChange={onChange}
      />
      <button className="flex bg-green-900 rounded-tr-lg rounded-br-lg hover:scale-125 transition-all" onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 m-auto text-white hover:scale-110 hover:text-slate-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
        </svg>
      </button>
    </div>
  );
}

