import styles from "./History.module.css";

export default function History({ question, onClick }) {
  return (
    <div className={"flex-1 p-5 mb-5 rounded-lg bg-gray-700 hover:bg-gray-500 w-full text-white group-hover:text-white"} onClick={onClick}>
      <p>{question.substring(0, 20)}...</p>
    </div>
  );
}

