import React, { useState } from "react";
import { hot } from "react-hot-loader/root";
import Markdown from 'react-markdown';
import "./App.css";
import Clear from "./components/Clear";
import History from "./components/History";
import Input from "./components/Input";
import Message from "./components/Message";
import logocni from './assets/images/Logo-CNI.png';
import logocnitext from './assets/images/Logo-CNI-with-text.png';

function App() {
   const [input, setInput] = useState("");
   const [messages, setMessages] = useState([]);
   const [history, setHistory] = useState([]);
   const handleSubmit = async () => {
      const prompt = {
         role: "user",
         content: input,
      };

      setMessages([...messages, prompt]);

      await fetch("https://api.openai.com/v1/chat/completions", {
         method: "POST",
         headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [...messages, prompt],
         }),
      })
         .then((data) => data.json())
         .then((data) => {
            const res = data.choices[0].message.content;
            setMessages((messages) => [
               ...messages,
               {
                  role: "assistant",
                  content: res,
               },
            ]);
            setHistory((history) => [...history, { question: input, answer: res }]);
            setInput("");
         });
   };
   const clear = () => {
      setMessages([]);
      setHistory([]);
   };

   /* createRoot(document.body).render(<Markdown>{markdown}</Markdown>) */

   return (
      <div className="">
         <div className="flex bg-white dark:bg-gray-900">
            <div className="w-20  text-gray-500 h-screen flex flex-col items-center justify-between py-5">
               <div className="">
                  <img src={logocni} alt="" className="m-auto w-10 rounded-lg" />
               </div>
               <div className="flex flex-col">
                  <div className="py-4 hover:text-gray-700">
                     <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                     </svg>
                  </div>
                  <div className="py-4 hover:text-gray-700 flex flex-col items-center justify-center text-blue-600">
                     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                     </svg>
                     <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                  </div>
                  <div className="py-4 hover:text-gray-700">
                     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                     </svg>
                  </div>
               </div>
               <div className="">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
               </div>
            </div>
            <div className="w-80 h-screen dark:bg-gray-800 bg-gray-100 p-2 hidden md:block">
               <div className="h-full overflow-y-auto">
                  <div className="text-xl font-extrabold text-gray-600 dark:text-gray-200 p-3">Dhika</div>
                  <div className="search-chat flex p-3">
                     <input className="input text-gray-700 dark:text-gray-200 text-sm p-3 focus:outline-none bg-gray-200 dark:bg-gray-700  w-full rounded-l-md" type="text" placeholder="Search Messages" />
                     <div className="bg-gray-200 dark:bg-gray-700 flex justify-center items-center pr-3 text-gray-400 rounded-r-md">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                     </div>
                  </div>
                  <div className="text-lg font-semibol text-gray-600 dark:text-gray-500 group p-3">Recent</div>
                  <div>
                     <div className={'conversation-item p-1 dark:bg-gray-700 m-1 rounded-md bg-gray-200'} >
                        <div className={'flex justify-between items-center p-2 cursor-pointer'}>
                           <div className="flex w-full flex-col">
                              {history.map((el, i) => {
                                 return (
                                    <History
                                       key={i}
                                       question={el.question}
                                       onClick={() =>
                                          setMessages([
                                             { role: "user", content: history[i].question },
                                             { role: "assistant", content: history[i].answer },
                                          ])
                                       }
                                    />
                                 );
                              })}
                           </div>
                           {/* <div className="w-7 h-7 m-1">
                              <img className="rounded-full" src="https://cdn.pixabay.com/photo/2017/01/31/21/23/avatar-2027366_960_720.png" alt="avatar" />
                              </div>
                              <div className="flex-grow p-2">
                              <div className="flex justify-between text-md ">
                                 <div className="text-sm font-medium text-gray-700 dark:text-gray-200">Text Judul</div>
                                 <div className="text-xs text-gray-400 dark:text-gray-300">Waktu</div>
                                 </div>
                                 <div className="text-sm text-gray-500 dark:text-gray-400  w-40 truncate">
                                 Isi Pesan
                                 </div>
                              </div> */}
                        </div>
                     </div>
                  </div>
                  <div className="justify-self-end">
                     <Clear onClick={clear} />
                  </div>

               </div>
            </div>
            <div className="flex-grow  h-screen p-2 rounded-md">
               <div className="flex-grow h-full flex flex-col">
                  <div className="w-full h-15 p-1 bg-purple-600 dark:bg-gray-800 shadow-md rounded-xl rounded-bl-none rounded-br-none">
                     <div className="flex p-2 align-middle items-center">
                        <div className="p-2 md:hidden rounded-full mr-1 hover:bg-purple-500 text-white">
                           <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                           </svg>
                        </div>
                        <div className="flex-grow p-2">
                           <div className="text-md text-gray-50 font-semibold">CNI Artificial Intelligence</div>
                           <div className="flex items-center">
                              <div className="w-2 h-2 bg-green-300 rounded-full"></div>
                              <div className="text-xs text-gray-50 ml-1">
                                 Online
                              </div>
                           </div>
                        </div>
                        <div className="p-2 text-white cursor-pointer hover:bg-purple-500 rounded-full">
                           <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                           </svg>
                        </div>
                     </div>
                  </div>
                  <div className="w-full flex-grow bg-gray-100 dark:bg-gray-900 my-2 p-2 overflow-y-auto">
                     {messages.map((el, i) => {
                        return <Message key={i} role={el.role} content=<Markdown>{el.content}</Markdown> />;
                     })}
                  </div>
                  <div className="h-15  p-3 rounded-xl rounded-tr-none rounded-tl-none bg-gray-100 dark:bg-gray-800">
                     <div className="flex items-center">
                        <div className="search-chat flex flex-grow p-2">
                           <Input
                              value={input}
                              onChange={(e) => setInput(e.target.value)}
                              onClick={input ? handleSubmit : undefined}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default hot(App);

