import styles from "./Clear.module.css";

export default function Clear({ onClick }) {
  return (
    <button className="h-10 mt-2 text-white w-full bg-gray-700 hover:bg-gray-900 font-bold rounded-lg transition-all" onClick={onClick}>
      Clear
    </button>
  );
}

